<template>
  <div>
    <a-modal
      okText="Masukkan"
      @ok="actionModal"
      @cancel="hideModal"
      cancelText="Batalkan"
      :title="`Buat Data ${formTitle}`"
      :width="900"
      :visible="visibleModal"
    >
      <a-form-model
        class="formOrangtua"
        :model="orangtua"
        ref="ruleForm"
        :rules="rules"
      >
        <a-form-model-item :colon="false" prop="nama">
          <span slot="label">
            Nama Lengkap&nbsp;
            <a-tooltip
              title="Nama ibu kandung peserta didik sesuai dokumen resmi yang berlaku. Hindari penggunaan gelar akademik atau sosial (seperti Almh., Dr., Dra., S.Pd, dan Hj.)."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input size="large" v-model="orangtua.nama"></a-input>
        </a-form-model-item>
        <a-form-model-item :colon="false" label="NIK" prop="nik">
          <a-input size="large" v-model="orangtua.nik"></a-input>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Tahun Lahir"
          prop="tahun_lahir"
        >
          <a-input-number
            size="large"
            :min="1900"
            :max="thisYear"
            v-model="orangtua.tahun_lahir"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Pendidikan"
          prop="pendidikan_terakhir"
        >
          <a-select size="large" v-model="orangtua.pendidikan_terakhir">
            <a-select-option
              v-for="pendidikan in educationsList"
              :key="pendidikan.key"
              :value="pendidikan.name"
              >{{ pendidikan.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item :colon="false" label="Pekerjaan" prop="pekerjaan">
          <a-select size="large" v-model="orangtua.pekerjaan">
            <a-select-option
              v-for="pekerjaan in worksList"
              :key="pekerjaan.key"
              :value="pekerjaan.name"
              >{{ pekerjaan.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Penghasilan Bulanan"
          prop="penghasilan_bulanan"
        >
          <a-select size="large" v-model="orangtua.penghasilan_bulanan">
            <a-select-option
              v-for="penghasilan in monthlyRevenueList"
              :key="penghasilan.key"
              :value="penghasilan.name"
              >{{ penghasilan.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          label="Berkebutuhan Khusus"
          prop="isBerkebutuhanKhusus"
        >
          <a-radio-group size="large" v-model="orangtua.isBerkebutuhanKhusus">
            <a-radio value="ya">Ya</a-radio>
            <a-radio value="tidak">Tidak</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          :colon="false"
          v-if="orangtua.isBerkebutuhanKhusus === 'ya'"
          label="Jenis Kebutuhan Khusus"
          prop="berkebutuhan_khusus"
        >
          <a-select
            mode="multiple"
            size="large"
            v-model="orangtua.berkebutuhan_khusus"
          >
            <a-select-option
              v-for="kebutuhan in disabledList"
              :key="kebutuhan.key"
              :value="kebutuhan.name"
              >{{ kebutuhan.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- <div>
      <div class="d-lg-flex flex-column flex-md-row align-items-center mb-4">
        <div class="ml-auto text-center">
          <a-dropdown>
            <a-button
                type="primary"
                icon="plus"
                size="large"
                class="w-100"
                >Buat Data Baru</a-button
              >

            <template slot="overlay">
              <a-menu @click="showModal">
                <a-menu-item :disabled="checkAvailData.ayah_kandung" key="ayah_kandung"> <a-icon type="user" />Ayah Kandung</a-menu-item>
                <a-menu-item :disabled="checkAvailData.ibu_kandung" key="ibu_kandung"> <a-icon type="user" />Ibu Kandung</a-menu-item>
                <a-menu-item :disabled="checkAvailData.wali" key="wali"> <a-icon type="user" />Wali</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div> -->
    <div class="mb-4">
      <a-table
        :columns="columns"
        :data-source="datas"
        :loading="loadingTable"
        :scroll="{ x: 1300 }"
        :pagination="{ pageSize: 30, hideOnSinglePage: true }"
        bordered
      >
        <div slot="hubungan" slot-scope="value">
          {{
            showHubungan(value) === "Wali"
              ? "Wali (opsional)"
              : showHubungan(value)
          }}
        </div>
        <span slot="nullStr" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="pendidikan" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{ setValueTable(text, "educationsList") }}</span>
        </span>
        <span slot="penghasilan" slot-scope="text">
          <span v-if="!text">-</span>
          <span v-else>{{ setValueTable(text, "monthlyRevenueList") }}</span>
        </span>
        <span slot="berkebutuhan_khusus" slot-scope="value, record">
          <span v-if="!record.berkebutuhan_khusus">-</span>
          <template v-else>
            <a-tag v-if="!record.berkebutuhan_khusus.length" color="green"
              >tidak</a-tag
            >
            <template v-else>
              <a-tag
                class="m-1"
                v-for="(kebutuhan, i) in record.berkebutuhan_khusus"
                :key="i"
                color="orange"
                >{{ kebutuhan }}</a-tag
              >
            </template>
          </template>
        </span>
        <div slot="actions" slot-scope="value, record">
          <a-button
            :type="record.nama ? 'default' : 'primary'"
            class="mr-3"
            :class="
              record.nama ? ['text-warning', 'border', 'border-warning'] : []
            "
            size="large"
            @click.prevent="showModal(record)"
          >
            <a-icon :type="record.nama ? 'edit' : 'plus'" />{{
              record.nama ? "Ubah" : "Masukkan"
            }}
          </a-button>
          <!-- <a-button
            class="text-danger border border-danger"
            size="large"
            @click.prevent="deleteData(record)"
          >
            <a-icon type="delete" />Delete
          </a-button> -->
        </div>
      </a-table>
    </div>
    <header-menu title="Kontak" />
    <a-form-model :model="kontak" :rules="rulesKontak" ref="ruleKontak">
      <a-form-model-item :colon="false" prop="nomor_telepon_rumah">
        <span slot="label">
          Nomor Telepon Rumah&nbsp;
          <a-tooltip
            title="Diisi nomor telepon rumah peserta didik yang dapat dihubungi (milik pribadi, orang tua, atau wali) dengan format <kode area><nomor telepon> tanpa '0' di depan. Contoh: 21775577"
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input
          addon-before="+62"
          size="large"
          placeholder="<kode area><nomor telepon> tanpa '0' di depan. Contoh: 21775577"
          v-model="kontak.nomor_telepon_rumah"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="nomor_hp">
        <span slot="label">
          Nomor HP&nbsp;
          <a-tooltip
            title="Diisi nomor telepon selular (ponsel) peserta didik yang dapat dihubungi (milik pribadi, orang tua, atau wali)."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <!-- <a-select slot="addonBefore" v-decorator="['prefix', { initialValue: '86' }]">
        </a-select> -->
        <a-input
          size="large"
          placeholder="Masukkan tanpa '0' di depan. Contoh: 87321123456"
          addon-before="+62"
          v-model="kontak.nomor_hp"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item
        :colon="false"
        prop="email"
      >
        <span slot="label">
          Email Peserta Didik&nbsp;
          <a-tooltip
            title="Diisi alamat surat elektronik (surel) peserta didik yang dapat dihubungi (milik pribadi, orang tua, atau wali)."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input size="large" v-model="kontak.email"></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="email_perwakilan_orangtua">
        <span slot="label">
          Email Orangtua/Wali&nbsp;
          <a-tooltip
            title="Diisi alamat surat elektronik (surel) Orangtua/Wali yang dapat dihubungi."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-input
          size="large"
          v-model="kontak.email_perwakilan_orangtua"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item :colon="false" prop="jenis_ekstrakurikuler">
        <span slot="label"> Jenis Ekstrakurikuler&nbsp; </span>
        <a-select
          size="large"
          mode="multiple"
          v-model="kontak.jenis_ekstrakurikuler"
        >
          <a-select-option
            v-for="ekskul in extracurricularList"
            :key="ekskul.key"
            :value="ekskul.name"
            >{{ ekskul.title }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { disabledList, worksList, educationsList, monthlyRevenueList, extracurricularList } from '@/helpers/listsPpdbSelect'

const HeaderMenu = () => import('@/components/app/Registration/Header')
const rules = {
  nama: [
    { required: true, message: 'Nama wajib diisi!', trigger: 'change' },
    { max: 32, message: 'Nama maksimum 32 huruf!', trigger: 'change' },
  ],
  nik: [
    { required: true, message: 'NIK/No. KITAS(WNA) wajib diisi!', trigger: 'change' },
    { max: 16, message: 'NIK/No. KITAS(WNA) maksimum berformat 16 angka!', trigger: 'blur' },
  ],
  tahun_lahir: [
    { required: true, message: 'Tahun Lahir wajib diisi!', trigger: 'change' },
    // { min: 1000, max: 9999, message: 'Masukkan tahun lahir dengan format yang benar (contoh: 1987)!', trigger: 'change' },
  ],
  isBerkebutuhanKhusus: [
    { required: true, message: 'Berkebutuhan Khusus wajib diisi!', trigger: 'change' },
  ],
  berkebutuhan_khusus: [
    { type: 'array', min: 1, required: true, message: 'Bila ya, jenis kebutuhan khusus wajib diisi!', trigger: 'change' },
  ],
  pendidikan_terakhir: [
    { required: true, message: 'Pendidikan wajib diisi!', trigger: 'change' },
  ],
  pekerjaan: [
    { required: true, message: 'Pekerjaan wajib diisi!', trigger: 'change' },
  ],
  penghasilan_bulanan: [
    { required: true, message: 'Penghasilan Bulanan wajib diisi!', trigger: 'change' },
  ],
}

const columns = [
  {
    title: 'Hubungan',
    dataIndex: 'tipe',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'hubungan',
    },
    fixed: 'left',
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'NIK',
    dataIndex: 'nik',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Tahun Lahir',
    dataIndex: 'tahun_lahir',
    align: 'center',
    width: 100,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Pendidikan',
    dataIndex: 'pendidikan_terakhir',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'pendidikan',
    },
  },
  {
    title: 'Penghasilan Bulanan',
    dataIndex: 'penghasilan_bulanan',
    align: 'center',
    width: 250,
    scopedSlots: {
      customRender: 'penghasilan',
    },
  },
  {
    title: 'Berkebutuhan Khusus',
    dataIndex: 'berkebutuhan_khusus',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'berkebutuhan_khusus',
    },
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    width: 200,
    fixed: 'right',
    scopedSlots: { customRender: 'actions' },
  },
]

export default {
  components: { HeaderMenu },
  data() {
    return {
      orangtua: {
        nama: null,
        nik: null,
        tahun_lahir: null,
        pendidikan_terakhir: null,
        pekerjaan: null,
        penghasilan_bulanan: null,
        berkebutuhan_khusus: [],
        tipe: null,
        isBerkebutuhanKhusus: null,
      },
      kontak: {
        nomor_telepon_rumah: null,
        nomor_hp: null,
        email: null,
        email_perwakilan_orangtua: null,
        jenis_ekstrakurikuler: [],
      },
      rules,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      // columns,
      loadingTable: false,
      visibleModal: false,
      validateStatusEmail: '',
      monthlyRevenueList,
      disabledList,
      educationsList,
      worksList,
      extracurricularList,
    }
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
      }
    },
    formTitle() {
      if (this.orangtua.tipe === 'ayah_kandung') {
        return 'Ayah Kandung'
      } else if (this.orangtua.tipe === 'ibu_kandung') {
        return 'Ibu Kandung'
      } else if (this.orangtua.tipe === 'wali') {
        return 'Wali'
      }

      return 'Orangtua/Wali'
    },
    checkAvailData() {
      const isExist = {
        ayah_kandung: false,
        ibu_kandung: false,
        wali: false,
      }

      this.datas.forEach(data => {
        for (const key in isExist) {
          if (key === data.tipe) {
            isExist[key] = true
            break
          }
        }
      })

      return isExist
    },
    isMobile() {
      return window.innerWidth < 768
    },
    isTablet() {
      return window.innerWidth >= 768 && window.innerWidth < 992
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    columns() {
      // console.log('masuk sini', this.isMobile, this.isDesktop, this.$store.getters)
      if (this.isDesktop) return columns
      return columns.map(col => {
        return {
          ...col,
          fixed: null,
        }
      })
    },
    thisYear() {
      return Number(new Date().getFullYear())
    },
    rulesKontak() {
      return {
        nomor_telepon_rumah: [
          { required: true, message: 'Nomor telepon rumah wajib diisi!', trigger: 'change' },
          { max: 16, message: 'Nomor telepon rumah maksimum 16 karakter!', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (isNaN(value)) {
                callback(new Error('Masukkan nomor telepon dengan format angka!'))
              } else if (value[0] === '0' || value[0] === 0) {
                callback(new Error('Masukkan nomor telepon dengan tanpa angka 0 didepan.'))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
        nomor_hp: [
          { required: true, message: 'Nomor HP wajib diisi!', trigger: 'change' },
          { max: 16, message: 'Nomor HP maksimum 16 karakter!', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (isNaN(value)) {
                callback(new Error('Masukkan nomor HP dengan format angka!'))
              } else if (value[0] === '0' || value[0] === 0) {
                callback(new Error('Masukkan nomor telepon dengan tanpa angka 0 didepan.'))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
        email: [
          { required: true, message: 'Email wajib diisi!', trigger: 'blur' },
          { type: 'email', message: 'Format email belum sesuai!', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              console.log(value, this.kontak.email_perwakilan_orangtua, 'dari email')
              if (value === this.kontak.email_perwakilan_orangtua) {
                callback(new Error('Email peserta didik tidak boleh sama dengan email orangtua/wali'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        email_perwakilan_orangtua: [
          { required: true, message: 'Email wajib diisi!', trigger: 'blur' },
          { type: 'email', message: 'Format email belum sesuai!', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              console.log(value, this.kontak.email, 'dari perwakilan')
              if (value === this.kontak.email) {
                callback(new Error('Email orangtua/wali tidak boleh sama dengan email peserta didik'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      }
    },
  },
  props: ['isClickAction', 'datas', 'inputKontak'],
  watch: {
    'isClickAction.next'(newVal) {
      if (newVal) {
        this.$refs.ruleKontak.validate(valid => {
          if (valid) {
            let orangtuaIsCompleted = true
            for (let i = 0; i < this.datas.length; i++) {
              const data = this.datas[i]
              if (data.tipe === 'ayah_kandung' || data.tipe === 'ibu_kandung') {
                if (!data.nama) {
                  this.$emit('actionValidate', false)
                  orangtuaIsCompleted = false
                  this.$notification.error({
                    message: 'Maaf',
                    description: `Harap isi data '${this.showHubungan(data.tipe)}'.`,
                  })
                  break
                }
              }
            }
            if (orangtuaIsCompleted) {
              this.$emit('actionValidate', { action: 'next', data: { kontak: this.kontak } })
            }
          } else {
            this.$emit('actionValidate', false)
            this.$notification.error({
              message: 'Maaf',
              description: 'Ada data wajib yang belum terisi/sesuai.',
            })
            return false
          }
        })
      }
    },
    'isClickAction.previous'(newVal) {
      if (newVal) {
        this.$emit('actionValidate', { action: 'previous', data: { kontak: this.kontak } })
        // this.$refs.ruleKontak.validate(valid => {
        // if (valid) {
        // } else {
        //   this.$emit('actionValidate', false)
        //   this.$notification.error({
        //     message: 'Maaf',
        //     description: 'Ada data wajib yang belum terisi.',
        //   })
        //   return false
        // }
        // })
      }
    },
  },
  methods: {
    validateUniqueEmail(rule, value, callback) {
      this.validateStatusEmail = 'warning'
      console.log(rule)
      callback(new Error('Email ini sudah digunakan, Silahkan masukan email lain'))
    },
    setValueTable(value, anchor) {
      const dataTitle = this[anchor].find(data => data.name === value)
      // console.log(value, dataTitle)
      if (dataTitle) return dataTitle.title
      return value
    },
    showHubungan(value) {
      if (value === 'ayah_kandung') {
        return 'Ayah Kandung'
      } else if (value === 'ibu_kandung') {
        return 'Ibu Kandung'
      } else if (value === 'wali') {
        return 'Wali'
      }
    },
    showModal(record) {
      this.orangtua = {
        ...this.orangtua,
        ...record,
      }
      this.visibleModal = true
    },
    hideModal() {
      this.visibleModal = false
      this.orangtua = {
        nama: null,
        nik: null,
        tahun_lahir: null,
        pekerjaan: null,
        penghasilan_bulanan: null,
        berkebutuhan_khusus: [],
        tipe: null,
        isBerkebutuhanKhusus: null,
      }
    },
    actionModal() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('actionValidate', { action: 'push', data: { orangtua: this.orangtua } })
          this.visibleModal = false
          this.orangtua = {
            nama: null,
            nik: null,
            tahun_lahir: null,
            pekerjaan: null,
            penghasilan_bulanan: null,
            berkebutuhan_khusus: [],
            tipe: null,
            isBerkebutuhanKhusus: null,
          }
        } else {
          this.$emit('actionValidate', false)
          this.$notification.error({
            message: 'Maaf',
            description: 'Ada data wajib yang belum terisi/sesuai. Periksa kembali.',
          })
          return false
        }
      })
    },
    deleteData(record) {
      const tipe = this.showHubungan(record.tipe)
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Apa Anda yakin ingin menghapus data {tipe} anda?</div>
        ),
        onOk: () => {
          this.$emit('delete', record)
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Hapus',
        cancelText: 'Batal',
      })
    },
  },
  created() {
    // console.log(this.inputKontak)
    this.kontak = {
      ...this.kontak,
      ...this.inputKontak,
    }
  },
}
</script>

<style lang="scss">
.formPersonal {
}
</style>
